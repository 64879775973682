import { render, staticRenderFns } from "./B2bWidget.vue?vue&type=template&id=5e14b2d6&scoped=true&"
import script from "./B2bWidget.vue?vue&type=script&lang=js&"
export * from "./B2bWidget.vue?vue&type=script&lang=js&"
import style0 from "./B2bWidget.vue?vue&type=style&index=0&id=5e14b2d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e14b2d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})
