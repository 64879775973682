<template>
  <v-container fluid no-gutters>
    <B2bTabs @addEntity="addEntity"/>

    <div class="d-flex justify-end align-center gap-x-8 mb-4">
      <v-select
          v-model="searchParams.status"
          :items="statuses"
          :menu-props="{ bottom: true, offsetY: true }"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          item-text="name"
          item-value="value"
          style="max-width: 150px !important;"
          outlined
          dense
          @change="loadPartners"
      />
      <v-autocomplete
          :items="b2bTypes"
          v-model="type_query"
          item-value="id"
          item-text="name"
          outlined
          multiple
          @change="loadPartners"
          background-color="#fff"
          placeholder="Type"
          class="q-autocomplete shadow-0"
          style="max-width: 180px !important;"
          hide-details="auto"
          dense
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggleSelect()">
            <v-list-item-action>
              <v-icon :color="type_query.length > 0 ? 'teal darken-4' : ''">{{
                  getServiceIcon()
                }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>All</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template
            v-if="type_query.length === b2bTypes.length"
            v-slot:selection="{ index }"
        >
          <span v-if="index === 0">All Types</span>
        </template>
        <template v-else v-slot:selection="{ item, index }">
          <span v-if="index === 0">{{ item.name }}</span>
          <span v-if="index === 1">, {{ item.name }}</span>
          <span v-if="index === 2">, ...</span>
        </template>
      </v-autocomplete>
      <v-text-field
          outlined
          placeholder="Search by Name"
          v-model="searchParams.name"
          @keydown="searchByName"
          background-color="#fff"
          class="q-autocomplete shadow-0"
          style="max-width: 400px !important;"
          hide-details="auto"
          dense
      ></v-text-field>
    </div>

    <v-row>
      <v-col v-for="partner in partners" :key="partner.id" md="4" xl="3" sm="6" >
        <b2b-widget
          v-bind="partner"
          @edit="editPartner"
          @delete="deletePartnerWarning"
          @configure="configurePartnerProducts"
        />
      </v-col>
    </v-row>
    <h3 class="text-center mt-12" v-if="partners.length == 0">
      No Entities present
    </h3>
    <v-pagination class="mt-8" v-model="page" :length="totalPages"></v-pagination>

    <AddEditPartner
        :add-entity-dialogue="addEntityDialogue"
        @close="()=>addEntityDialogue=false"
        @refresh="loadPartners"
        v-if="addEntityDialogue"
    />

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>
<script>
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import B2bWidget from "@/components/B2b/B2bWidget.vue";
import b2bFields from "@/mixins/b2bFieldValidation";
import b2b from "@/mixins/b2b";
import constants from "@/utils/constants";
import B2bTabs from "@/views/B2b/B2bTabs.vue";
import AddEditPartner from "@/views/B2b/AddEditPartner.vue";
export default {
  components: {
    AddEditPartner,
    B2bTabs,
    ConfirmModel,
    B2bWidget,
  },
  mixins: [b2b, b2bFields],
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getB2bTypes.status == false) {
      this.$store.dispatch("loadB2bTypes");
    }
    if (this.$store.getters.getB2bCities.status == false) {
      this.$store.dispatch("loadB2bCities");
    }
    this.loadPartners();

    if (!this.$store.getters.configurationStatus("B2B")) {
      this.$store.dispatch("loadConfigurations", "B2B").then(() => {
        this.setDocumentFields();
        this.setFieldConfigurations();
        this.checkProductEnable();
      });
    } else {
      this.setDocumentFields();
      this.setFieldConfigurations();
      this.checkProductEnable();
    }
  },
  computed: {
    b2bTypes() {
      return this.$store.getters.getB2bTypes.data;
    },
    b2bCities() {
      return this.$store.getters.getB2bCities.data;
    },
    b2bConfig() {
      return this.$store.getters.getB2bConfig;
    },
  },
  watch: {
    'entity.b2b_type_id'(val){
      this.isB2E = val === this.b2bTypes.find((ele) => {
        return ele.name === 'B2E'
      })?.id;
    },
    addEntityDialogue(val) {
      if (val == false) {
        this.setDocumentFields();
      }
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    page: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadPartners();
        }
      },
    },
  },
  data() {
    return {
      isB2E:false,
      sectors:constants.sectors,
      percentageRules: [
        (value) => {
          if (value && !/^\d+(\.\d+)?%?$/.test(value)) {
            return "Invalid percentage format";
          }
          return true;
        },
      ],
      page: 1,
      productType: "B2B",
      editMode: false,
      addEntityDialogue: false,
      allowProducts: 0,
      entity: {
        id: null,
        entity_name: null,
        profile_image: null,
        b2b_type_id: null,
        contact_person_name: null,
        mobile: null,
        description: null,
        city_id: null,
        sector: null,
        email: null,
        allow_credit: "0",
        enable_commission: "0",
        commission_percentage: 0,
        documents: [
          {
            name: null,
            original_file_name: null,
            file: null,
          },
        ],
        deletedDocuments: [],
      },
      partners: [],
      totalPages: 0,
      valid: true,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      type_query: [],
      searchParams: {
        status:null
      },
      name: null,
      isLoading: false,
      names: [],
      //   b2bTypes: [
      //     { id: 36, name: "B2E" },
      //     { id: 37, name: "B2B" },
      //     { id: 38, name: "B2G" },
      //   ],
      statuses: [
        { name: "All", value: null },
        { name: "Active", value: 1 },
        { name: "Inactive", value: 2 },
      ],
      debounceTimeout: null,
    };
  },
  methods: {
    checkProductEnable() {
      if (this.b2bConfig && this.b2bConfig.enable_specific_products) {
        this.allowProducts = 1;
      } else {
        this.allowProducts = 0;
      }
    },
    filterSearch(type, val) {
      this.isLoading = true;
      this.$http
        .get("venues/b2b/filters?field=" + type + "&search=" + val)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    documentUploadRule(field) {
      const rules = [];
      if (field.is_required && !field.isEdit) {
        const rule = (v) => !!v || "Document is required";
        rules.push(rule);
      }
      return rules;
    },
    addDocuments() {
      this.entity.documents.push({
        name: "",
        document_type_id: "",
        file: null,
      });
    },
    deleteDocuments(index) {
      if (this.entity.documents[index].b2b_document_id) {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this document?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "document",
        };
      } else {
        this.entity.documents.splice(index, 1);
        if (this.entity.documents.length == 0) {
          this.entity.documents = [{}];
        }
      }
    },
    cancelEntity() {
      this.addEntityDialogue = false;
      this.entity = {
        id: null,
        entity_name: null,
        profile_image: null,
        b2b_type_id: null,
        contact_person_name: null,
        phone: null,
        description: null,
        city_id: null,
        sector: null,
        email: null,
        documents: [
          {
            name: null,
            file: null,
          },
        ],
        deletedDocuments: [],
      };
      setTimeout(() => {
        this.$refs.form.resetValidation();
      });
    },
    // addOrEditEntity() {
    //   console.log("adding");
    //   this.addOrEditEntity();
    // },

    editPartner(id) {
      this.editMode = true;
      this.$http
        .get(`venues/b2b/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.entity = response.data.data;

            if (this.entity.documents) {
              this.entity.documents.forEach((document) => {
                this.documentFields.forEach((documentField) => {
                  if (documentField.id == document.felid_id) {
                    documentField.original_file_name =
                      document.original_file_name;
                    documentField.file_path = document.file_path;
                    documentField.document_id = document.id;
                    documentField.isEdit = true;
                  }
                });
              });
            }

            if (this.entity.allow_credit) {
              this.entity.allow_credit = this.entity.allow_credit.toString();
            } else {
              this.entity.allow_credit = "0";
            }
            if (this.entity.enable_commission) {
              this.entity.enable_commission = this.entity.enable_commission.toString();
            } else {
              this.entity.enable_commission = "0";
            }

            this.entity.deletedDocuments = [];
            this.addEntityDialogue = true;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    addEntity() {
      this.addEntityDialogue = true;
    },
    gotoConfiguration() {
      this.$router.push({
        name: "B2bConfiguration",
      });
    },

    loadPartners() {
      this.showLoader("Loading");
      let url = "";
      if (this.type_query != null) {
        url = this.type_query
          .map((item, index) => `&b2b_type_id[${index}]=${item}`)
          .join(",");
      }
      if (this.searchParams.name == "All") {
        delete this.searchParams.name;
      } else if (this.searchParams.name) {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.status){
        url += "&status=" + this.searchParams.status;
      }
      this.$http
        .get(`venues/b2b?page=${this.page}${url}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.partners = [];
            let data = response.data.data;
            data.forEach((datum) => {
              if (datum.customers && datum.customers.length > 0) {
                datum.members = datum.customers.length;
              } else {
                datum.members = 0;
              }
              this.partners.push(datum);
            });
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (this.type_query.length == this.b2bTypes.length) {
          this.type_query = [];
        } else {
          this.type_query = this.b2bTypes.map((item) => item.id);
        }
      });
      setTimeout(() => {
        this.loadPartners();
      });
    },
    getServiceIcon() {
      if (this.type_query.length == 0) return "mdi-checkbox-blank-outline";
      if (this.b2bTypes.length == this.type_query.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },

    confirmActions(data) {
      if (data.type == "delete") {
        this.deleteEntity(data.id);
      }
      if (data.type == "document") {
        if (this.entity.documents[data.id].b2b_document_id != null)
          this.entity.deletedDocuments.push(
            this.entity.documents[data.id].b2b_document_id
          );

        if (this.entity.documents[data.id].file) {
          this.entity.documents[data.id].file = null;
        }
        this.entity.documents.splice(data.id, 1);
        if (this.entity.documents.length == 0) {
          this.entity.documents = [{}];
        }
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    deletePartnerWarning(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to delete this Entity?`,
        description: `By clicking <b>Yes</b> you can confirm delete this Entity.  Do you need to continue your action ?`,
        type: "delete",
      };
    },
    configurePartnerProducts(data) {
      this.$router.push({
        name: "B2bPartnerProducts",
        params: { data: btoa(data.id) },
      });
    },
    deleteEntity(id) {
      this.$http
        .get(`venues/b2b/deactivate/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.loadPartners();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    searchByName(){
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.loadPartners();
      }, 500);
    }
  },
};
</script>
<style>
.no-shadow-autocomplete
  .v-input--is-label-active:not(.v-input--is-dirty)::after,
.no-shadow-autocomplete .v-input--selection-controls {
  box-shadow: none !important;
}
</style>
