<template>
  <v-card class="shadow b2b_card" style="border-radius: 8px" @click="viewPartner">
    <div :class="{'in-active':status_id === 2,'active':status_id === 1}">
      {{ status_id === 2 ? 'Inactive' : 'Active'}}
    </div>
    <v-card-text>
      <div class="row row--dense no-gutters border-bottom pb-4">
        <div class="col-md-4 col-lg-4 col-sm-12 trainer_img_container">
          <simple-image :image="image_path" defaultImage="user" style="border-radius: 6px"></simple-image>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 ">
          <div class="trainer-details  ml-3 mt-4 mt-lg-0">
            <div>
              <p class="font-semibold text-lg black--text line-clamp-1 mb-0">{{ entity_name }}</p>
              <small>
                {{ type }}
              </small>
            </div>
            <div class="d-flex gap-x-2 align-center mt-5 stats_col">
              <SMSIcon style="min-width: 24px"/>
              <span class="black--text text-base  line-clamp-1">{{ email }}</span>
            </div>
            <div class="d-flex gap-x-2 align-center mt-2 stats_col">
              <CallIcon style="min-width: 24px"/>
              <span class="black--text text-base email_trainer">{{ phone }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row row--dense mt-4">
        <div class="col-6 justify-center text-center">
          <div class="stats">
            <p class="text-sm text-dark-gray">Members</p>
            <p class="text-base font-medium text-blue ">{{ Number(members) | numberFormatter }}</p>
          </div>
        </div>
        <div class="col-6">
          <div class="stats text-center">
            <p class="text-sm text-dark-gray">Bookings</p>
            <p class="text-base font-medium text-blue">{{ Number(bookings) | numberFormatter }} </p>
          </div>
        </div>

        <div class="col-6">
          <div class="stats text-center">
            <p class="text-sm text-dark-gray">Sales</p>
            <p class="text-base font-medium text-blue">{{ Number(sales)  | toCurrency }} </p>
          </div>
        </div>
        <div class="col-6">
          <div class="stats text-center">
            <p class="text-sm text-dark-gray">Invoice Dues</p>
            <p class="text-base font-medium text-blue">{{ Number(credit_due) | toCurrency }} </p>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import SMSIcon from "@/assets/images/misc/sms.svg";
import CallIcon from "@/assets/images/misc/call.svg";
import SimpleImage from "@/components/Image/SimpleImage.vue";

export default {
  components: {SimpleImage, CallIcon, SMSIcon},
  props: {
    id: {type: Number, default: 0},
    entity_name: {type: String, default: ""},
    type: {type: String, default: ""},
    email: {type: String, default: ""},
    phone: {type: String, default: ""},
    credit_due: {type: Number, default: 0},
    sales: {type: Number, default: 0},
    members: {type: Number, default: 0},
    bookings: {type: Number, default: 0},
    status_id: {type: Number, default: 1},
    image_path: {
      type: String,
      default: null,
    },
  },
  methods: {
    viewPartner() {
      this.$router.push({
        name: "B2bPartnersView",
        params: {data: btoa(this.id)},
      });
    },
    checkProductEnable() {
      if (this.b2bConfig && this.b2bConfig.enable_specific_products) {
        this.allowProducts = 1;
      } else {
        this.allowProducts = 0;
      }
    },
  },
  data() {
    return {
      allowProducts: 0,
    };
  },
  mounted() {
    if (!this.$store.getters.configurationStatus("B2B")) {
      this.$store.dispatch("loadConfigurations", "B2B").then(() => {
        this.checkProductEnable();
      });
    } else {
      this.checkProductEnable();
    }
  },
  computed: {
    b2bConfig() {
      return this.$store.getters.getB2bConfig;
    },
  },
};
</script>
<style lang="scss" scoped>

.b2b_card {
  border: 1px solid rgba(17, 42, 70, 0);
  cursor: pointer;
  .in-active{
    color: rgba(255, 0, 0, 0.7);
    display: flex;
    align-items: center;
    opacity: 1;
    position: absolute;
    right: 8px;
    top: 5px;
    border-radius: 50%;
    gap: 2px;
    font-size: 12px;
    &::before{
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      background: rgba(255, 0, 0, 0.7);
      border-radius: 50%;
    }
  }
  .active{
    color: rgba(0, 128, 0, 0.7);
    display: flex;
    align-items: center;
    opacity: 1;
    position: absolute;
    right: 8px;
    top: 5px;
    border-radius: 50%;
    gap: 2px;
    font-size: 12px;
    &::before{
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      background: rgba(0, 128, 0, 0.7);
      border-radius: 50%;
    }
  }
}

.b2b_card:hover {
  border: 1px solid rgba(17, 42, 70, 1);
  box-shadow: 0 8px 24px 0 rgba(70, 76, 136, 0.20) !important
}

.b2b_card {
  .v-card__text {
    line-height: 1.5;
  }
  .stats{
    p{
      margin: 0;
    }
  }
}

</style>
